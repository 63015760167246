import React, { useState, useEffect } from "react";
import "./home.scss";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import Web3 from "web3";
import Carousel from "../../components/Carousel/Carousel";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

function Home() {
  const [web3, setWeb3] = useState("");
  const [wallerAddress, setWalletAddress] = useState("");

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);
  }, []);

  function connect() {
    web3.eth.requestAccounts().then((res) => {
      setWalletAddress(res[0]);
      web3.eth.defaultAccount = res[0];
    });
  }

  return (
    <div className="root">
      <div>
        <Container fluid>
          <Navbar className="cus-nav" variant="dark" expand="lg">
            <Navbar.Brand
              href="#top"
              className="font-weight-bold text-uppercase"
            >
              <img
                className="img-fluid nav-logo"
                src={require("../../assets/Logo.png").default}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#about" className="link-style linkh">
                  About
                </Nav.Link>

                <Nav.Link href="#roadmap" className="link-style linkh">
                  Roadmap
                </Nav.Link>
                <Nav.Link href="#team" className="link-style linkh">
                  THE TEAM
                </Nav.Link>
                <Nav.Link href="#faq" className="link-style linkh">
                  FAQ
                </Nav.Link>
                <Nav.Link
                  onClick={connect}
                  className="link-style btn btn-outline-pri"
                >
                  <span>
                    {wallerAddress
                      ? wallerAddress.substring(0, 15)
                      : "Connect Wallet"}
                  </span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>

      <div id="home" className="home ">
        <div className="showcase"></div>
        <Container className="buydiv d-flex align-items-center justify-content-center">
          <Row className="mt-4 text-center">
            <Col className="" lg="12">
              <div>
                <div className="">
                  <p className="font-weight-bold text-center">
                    Gym Rats is a collection of 10,000 NFTs
                  </p>
                </div>
                <div className="mt-4 btn bg-pri btn-lg text-uppercase font-weight-bold">
                  <span className="text-center">
                    {/* {str}{" "} */}
                    Loading
                  </span>
                </div>
                <div className="mt-4">
                  <h2 className="font-weight-bold">
                    <span className="">0</span> /{" "}
                    <span className="text-pri">10000</span>
                  </h2>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="about" className="about">
        <Container className=" h-100">
          <Row className=" h-100">
            <Col
              lg="6"
              className="d-flex align-items-center justify-content-center"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="aboutimg p-5">
                <img
                  className="img-fluid about-img"
                  src={require("../../assets/111.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              lg="6"
              className="d-flex align-items-center"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="about-text">
                <h1 className="title text-pri text-uppercase">Mission</h1>
                <p className="mt-3 subtitle">
                  Being a part of Gym Rats is more than just owning an NFT. It
                  is a community, a culture, and an identity. Our mission is to
                  have a community of driven individuals who push and inspire
                  one another to achieve their fitness goals and reach their
                  full potential. Through our community social media content and
                  Metaverse showcase, we hope to motivate others to be more
                  active and live a healthy lifestyle.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="gallery" className="gallery mx-5">
        <Container className="" fluid>
          <Row>
            <Col>
              <Carousel />
            </Col>
          </Row>
        </Container>
      </div>

      <div id="roadmap" className="roadmap">
        <Container className="r-content h-100">
          <Row className=" h-100">
            <Col lg="12" className="">
              <div className="">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title text-uppercase text-pri"
                >
                  Roadmap 1.0
                </h1>
                <div className="row text-wrapper">
                  <div className="col">
                    <div
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      className=""
                    >
                      <span className="number-text">
                        Member Driven Social Media Platforms
                      </span>
                    </div>
                    <div className="mt-2">
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        className="subtitle"
                      >
                        Gym Rats holders submit videos for a chance to win our
                        weekly YouTube Takeover. The winner gets to post a video
                        taking you through their workout routine and share their
                        story. The floor is yours.
                      </p>
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        className="subtitle"
                      >
                        Members can tag Gym Rats Snapchat while working out for
                        a chance to get reposted on our Snapchat story.
                      </p>
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        className="subtitle"
                      >
                        We will also have a Tik Tok with gym and fitness content
                        provided by the community through submissions.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="row text-wrapper"
                >
                  <div className="col">
                    <div className="">
                      <span className="number-text">Exclusive Merchandise</span>
                    </div>
                    <div className="mt-2">
                      <p className="subtitle">
                        Gym Rats NFT owners will have access to merchandise
                        where they can showcase their rats. Our online store
                        will include T-shirts, tank tops, gym shorts, hats,
                        protein shakers, gym bags, and much more.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="row text-wrapper"
                >
                  <div className="col">
                    <div className="">
                      <span className="number-text">
                        Exclusive Workout Programs
                      </span>
                    </div>
                    <div className="mt-2">
                      <p className="subtitle">
                        We will have several workout programs focused towards
                        different goals for members to access.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="row text-wrapper"
                >
                  <div className="col">
                    <div className="">
                      <span className="number-text">Weekly Challenges</span>
                    </div>
                    <div className="mt-2">
                      <p className="subtitle">
                        We will have weekly fitness challenges for members to
                        complete. Completing the challenge will enter you into a
                        raffle for that week’s giveaway.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="row text-wrapper"
                >
                  <div className="col">
                    <div className="">
                      <span className="number-text">Metaverse Showcase</span>
                    </div>
                    <div className="mt-2">
                      <p className="subtitle">
                        After selling out the collection, we will start to
                        search for land in Decentraland Metaverse for Gym Rats.
                        Our vision is to have a showcase in the Metaverse where
                        we can show videos of members working out and stream our
                        weekly YouTube competition winners for everyone to see.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="team" className="team">
        <Container className=" h-100">
          <div className="">
            <h1
              data-aos="fade-up"
              data-aos-duration="2000"
              className="title text-uppercase font-weight-bold text-pri"
            >
              THE TEAM
            </h1>
            <div data-aos="fade-up" data-aos-duration="2000" className="">
              <span className="sub">Meet The Gym Rats Team</span>
            </div>
          </div>
          <Row className="h-100 mt-5">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className=""
            >
              <div className=" text-center">
                <img
                  className="img-fluid team-img"
                  src={require("../../assets/3.png").default}
                  alt=""
                />
                <div className="mt-4">
                  <h4 className="font-weight-bold text-uppercase">Allen</h4>
                  <p>
                    Gym Rats Founder, Brand Manager. College Baseball Player and
                    NFT Enthusiast who loves to work out and wants to help
                    others achieve their fitness goals.
                  </p>
                  <div className="">
                    <a
                      href="https://twitter.com/ace6_ad"
                      className="mx-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="img-fuild si"
                        src={require("../../assets/twitter.png").default}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className=""
            >
              <div className=" text-center">
                <img
                  className="img-fluid team-img"
                  src={require("../../assets/6.png").default}
                  alt=""
                />
                <div className="mt-4">
                  <h4 className="font-weight-bold text-uppercase">Paresh</h4>
                  <p>Blockchain Developer</p>
                  <div className="">
                    <a
                      href="https://twitter.com/prshnandaniya"
                      className="mx-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="img-fuild si"
                        src={require("../../assets/twitter.png").default}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className=""
            >
              <div className=" text-center">
                <img
                  className="img-fluid team-img"
                  src={require("../../assets/qq.png").default}
                  alt=""
                />
                <div className="mt-4">
                  <h4 className="font-weight-bold text-uppercase">Victor</h4>
                  <p>
                    Artist. Professional Illustrator with more than 25 years of
                    experience and mastery in more than 15 drawing styles.
                  </p>
                  <div className="">
                    <a
                      href="https://www.instagram.com/vicart_ven/"
                      className="mx-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="img-fuild si"
                        src={require("../../assets/instagram.png").default}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="faq" className="faq text-center">
        <div className="faq-content">
          <div className="text-center">
            <h1
              data-aos="fade-up"
              data-aos-duration="2000"
              className="title text-pri font-weight-bold text-uppercase"
            >
              Faq
            </h1>
          </div>
          <Container className="mt-5">
            <Row>
              <Col lg="12">
                <Accordion>
                  <AccordionItem
                    className="bt pt-2"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton className=" text-center">
                        <h4 className="subtitle font-weight-bold">
                          What is Gym Rats?
                        </h4>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div>
                        <span className="">
                          Gym Rats is a collection of 10,000 NFTs with 120+
                          unique and rare traits on the Ethereum Blockchain as
                          ERC-721 tokens and hosted on IPFS.
                        </span>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem
                    className="bt pt-2"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton className=" text-center">
                        <h4 className="subtitle font-weight-bold">
                          Release Date?
                        </h4>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="">
                        <span>
                          Our release date will be in early January 2022. More
                          details will be given in our Discord.
                        </span>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem
                    className="bt pt-2"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton className=" text-center">
                        <h4 className="subtitle font-weight-bold">
                          Where to buy?
                        </h4>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="">
                        <span>
                          Gym Rats will be available for minting on our official
                          website. After minting, you can showcase and trade
                          your Gym Rats on OpenSea.
                        </span>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem
                    className="bt pt-2"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton className=" text-center">
                        <h4 className="subtitle font-weight-bold">
                          How much will it cost?
                        </h4>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="">
                        <span className="subtitle">
                          Each Gym Rat in the Public Sale will cost 0.06 ETH
                          (+gas).
                        </span>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem
                    className="bt pt-2"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton className=" text-center">
                        <h4 className="subtitle font-weight-bold">
                          Will there be a Presale and Whitelist?
                        </h4>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="">
                        <span className="subtitle">
                          Yes, there will be a Whitelist with 1,000 spots for
                          the Presale. Presale will happen a couple of days
                          before the Public Sale and cost 0.05 ETH (+gas). Join
                          the Discord for more info on how to get Whitelisted.{" "}
                        </span>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
