import React from "react";
import "./footer.scss";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <div className="footer p-3">
      <Container className="">
        <Row>
          <Col lg="4" className="mb-4">
            <div className="mbc text-center footer-img-wrapper ">
              <a href="#top">
                <img
                  className="img-fluid footer-logo "
                  src={require("../../assets/Logo1.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
            </div>
          </Col>
          <Col lg="4" className="mb-4">
            <div className="mbc social-logo h-100 d-flex justify-content-around align-items-center text-center">
              <a
                href="https://discord.gg/FvF5Qfnt"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ height: "25px" }}
                  className="img-fluid  "
                  src={require("../../assets/discord.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
              <a
                href="https://twitter.com/GymRats_NFT"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  style={{ height: "25px" }}
                  className="img-fluid  "
                  src={require("../../assets/twitter.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a>
              {/* <a href="#" rel="noreferrer" target="_blank">
                <img
                  style={{ height: "35px" }}
                  className="img-fluid  "
                  src={require("../../assets/opensea.png").default}
                  alt=""
                  rel="noreferrer"
                />
              </a> */}
            </div>
          </Col>
          <Col lg="4" className="mb-4">
            <div className="mbc h-100 d-flex justify-content-center align-items-center">
              <div className="">
                <span className="font-weight-bold">
                  © {new Date().getFullYear()} Gym Rats. All rights reserved.
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
